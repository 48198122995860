import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { memo } from '../../../util/memo';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { useCountViewers } from '../../../hooks/mux/useCountViewers';
import { useMemo } from 'react';
import { LiveBadge } from '../../LiveBadge';
import { useTheme } from '@mui/material/styles';

export type ViewerCountProps = {
  playbackId?: string;
  livestreamId?: string;
  isLatestPlayback?: boolean;
  isVisible?: boolean;
};

const ViewerCountUnmemoized = ({
  playbackId,
  livestreamId,
  isLatestPlayback,
  isVisible = true,
}: ViewerCountProps) => {
  const theme = useTheme();
  const { viewerCount, viewsTotal } = useCountViewers({
    livestreamId,
    playbackId,
    isLatestPlayback,
  });

  const viewsIndicator = useMemo(() => {
    return !!viewerCount ? (
      <VisibilityOutlinedIcon sx={{ height: '18px', mt: '2px' }} />
    ) : !!viewsTotal ? (
      <Typography variant="subtitle2">{`VIEW${
        viewsTotal !== 1 ? 'S' : ''
      }`}</Typography>
    ) : (
      <></>
    );
  }, [viewerCount, viewsTotal]);

  const count = useMemo(() => {
    return (
      <Typography variant="subtitle2">{viewerCount || viewsTotal}</Typography>
    );
  }, [viewerCount, viewsTotal]);

  const liveBadge = useMemo(() => {
    return !!viewerCount && <LiveBadge />;
  }, [viewerCount]);

  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        pr: 2,
        pl: viewerCount ? 0 : 2,
        opacity: isVisible ? 1 : 0,
        borderRadius: '4px',
        background:
          !!viewerCount || !!viewsTotal
            ? theme.palette.background.shades.opacity60
            : 'transparent',
      }}
    >
      {liveBadge}
      <Stack
        alignItems="center"
        direction={!!viewerCount ? 'row' : 'row-reverse'}
        spacing={!!viewerCount ? 0 : 1}
        sx={{ height: '20px' }}
      >
        {viewsIndicator}
        {count}
      </Stack>
    </Stack>
  );
};

export const ViewerCount = memo(ViewerCountUnmemoized);
