import Box from '@mui/material/Box';
import { memo } from '../../../util/memo';
import { ReactNode } from 'react';
import { ViewerCount, ViewerCountProps } from './ViewerCount';
import { HoverProvider } from '../../../contexts/HoverContext';
import { ViewerCountHoverable } from './ViewerCountHoverable';
import { SxProps } from '@mui/material/styles';

export type ViewerCountDynamicProps = ViewerCountProps & {
  children?: ReactNode;
  sx?: SxProps;
};

const ViewerCountDynamicUnmemoized = ({
  children,
  sx = {},
  ...props
}: ViewerCountDynamicProps) => {
  if (children) {
    return (
      <HoverProvider>
        {children}
        <Box sx={sx}>
          <ViewerCountHoverable {...props} />
        </Box>
      </HoverProvider>
    );
  }

  return (
    <Box sx={sx}>
      <ViewerCount {...props} />
    </Box>
  );
};

export const ViewerCountDynamic = memo(ViewerCountDynamicUnmemoized);
